import {ref} from 'vue';

export default function (min) {
    let height = ref(min);

    /**
     * 动画执行之前
     *
     * @param el
     */
    function beforeEnter(el) {
        el.style.display = 'block';
        el.style.height = 'auto';
        let tmpHeight = el.offsetHeight;
        el.style.display = 'none';

        if (height.value === min || height.value !== tmpHeight) height.value = tmpHeight;

        el.style.height = min;
    }

    /**
     * 动画执行
     *
     * @param el
     */
    function enter(el) {
        // 重新绘制DOM元素
        el.offsetWidth;

        el.style.height = height.value + 'px';
    }

    /**
     * 动画执行之后
     *
     * @param el
     */
    function afterEnter(el) {
        el.style.height = 'auto';
    }

    /**
     * 动画还原之前
     *
     * @param el
     */
    function beforeLeave(el) {
        height.value = el.offsetHeight;

        el.style.height = height.value + 'px';
    }

    /**
     * 动画还原
     *
     * @param el
     */
    function leave(el) {
        el.style.height = min;
    }

    /**
     * 动画还原之后
     *
     * @param el
     */
    function afterLeave(el) {
        el.style.height = 'auto';
    }

    return {
        height,

        beforeEnter,
        enter,
        afterEnter,
        beforeLeave,
        leave,
        afterLeave
    };
}
import {createI18n} from "vue-i18n/index";
import ElementLangZh from "element-plus/lib/locale/lang/zh-cn"
import ElementLangEn from "element-plus/lib/locale/lang/en"
import zh from "./zh"
import en from "./en"

const messages = {
    'zh': {
        ...ElementLangZh,

        message: {
            ...zh
        }
    },

    'en': {
        ...ElementLangEn,

        message: {
            ...en
        }
    }
}

export default createI18n({
    locale: window.localStorage.getItem('locale') || 'zh',

    messages
})
import {ref} from 'vue'
import screenfull from "screenfull";

let full = ref(false);

// 监听全屏切换
screenfull.on('change', function () {
    full.value = screenfull.isFullscreen;
});

export default function () {
    /**
     * 切换全屏
     */
    function switchFull() {
        screenfull.toggle();
    }

    return {
        full,

        switchFull
    }
}
import {reactive} from "vue"
import {throttling} from "@/assets/js/function";
import layout from "@/use/layout";

// 菜单搜索配置参数
let search = reactive({
    menu: [],

    // 关键字
    keyword: '',

    timer: null,

    // 是否显示搜索框
    show: false,

    // 搜索数据
    dropStatus: false,
    data: [
        {
            id: 1,
            name: '一一一asdf asdf asdfdasfasdfasdfads dsaf asdf sdaf ads ',
            url: '',
            type: 0,
        },

        {
            id: 1,
            name: '一一一',
            url: '',
            type: 0,
        },

        {
            id: 1,
            name: '一一一',
            url: '',
            type: 0,
        },

        {
            id: 1,
            name: '一一一',
            url: '',
            type: 0,
        },
    ],

    // 更新搜索框状态
    update: (val) => {
        search.show = val;
    },

    // 更新下拉框显示状态
    updateDropStatus: (val) => {
        search.dropStatus = val;
    },

    // 更新搜索框全部状态
    updateAllStatus: val => {
        search.show = val;
        search.dropStatus = val;

        search.keyword = '';
        search.data = [];
    }
});

// 个人中心配置参数
let personal = reactive({
    show: false,

    // 更新状态
    update: (val) => {
        personal.show = val;
    }
});

// 组件相关配置
let config = reactive({
    // 菜单按钮组件 fold 待关闭 expand 待展开
    menuBtnComponent: {
        status: 0,

        list: ['fold', 'expand']
    },

    // document 点击事件，默认关闭所有下拉框
    documentClick: true,
});

// 面包屑导航、个人中心下拉菜单列表、菜单切换按钮、菜单选中处理逻辑
let {layTop, menuSwitchBtnClick: menuZoomControl, handlerMenuClickSelectLogic} = layout();

export default function () {
    /**
     * 默认行为
     *
     * @param menu
     */
    function init(menu) {
        // 处理菜单结构
        search.menu = handlerMenuFormat(menu, []);

        // 点击 document 触发事件
        window.addEventListener('click', function () {
            // 关闭下拉菜单，因点击事件无法阻止冒泡，故延时执行，根据配置条件执行是否关闭
            setTimeout(() => {
                if (config.documentClick) closeDropMenuStatus();

                // 执行完成，并开启冒泡
                config.documentClick = true;
            }, 1);
        }, true)
    }

    /**
     * 关闭所有下拉菜单
     *
     * @param type 0 关闭所有下拉框 1 关闭搜索下拉状态 2 关闭个人中心下拉状态
     * @param isBubble 是否阻止冒泡（触发桌面点击事件）
     */
    function closeDropMenuStatus(type = 0, isBubble = false) {
        if (isBubble) config.documentClick = false;

        if (type !== 1) personal.update(false);

        if (type !== 2) search.updateAllStatus(false);
    }

    /**
     * 搜索菜单所需的菜单格式
     *
     * @param menus
     * @param old
     * @returns {[]}
     */
    function handlerMenuFormat(menus, old) {
        let data = [];

        menus.forEach(item => {
            let tmp = old.concat(item);

            if (item.children.length !== 0) {
                data = data.concat(handlerMenuFormat(item.children, tmp));
            } else {
                data[item.id] = tmp;
            }
        });

        return data;
    }

    /**
     * 菜单关键字搜索
     *
     * @param e
     */
    function onInputSearchMenu(e) {
        search.timer = throttling(search.timer, () => {
            let data = [];

            if (search.keyword !== "") {
                search.menu.forEach(item => {
                    let isContain = false;

                    let nav = item.map(navItem => {
                        if (-1 !== navItem.name.indexOf(search.keyword)) isContain = true;

                        return navItem.name;
                    });

                    if (isContain) data.push({
                        id: item[item.length - 1].id,
                        name: nav.join(' > '),
                        show: 0,
                        jump: 0,
                        i18n: false,
                        url: item[item.length - 1].url,
                        original: item[item.length - 1]
                    });
                });
            }

            search.data = data;
            search.updateDropStatus(search.keyword !== "");
        });
    }

    /**
     * 点击 搜索下拉菜单项
     *
     * @param val
     */
    function searchDropListClick(val) {
        handlerMenuClickSelectLogic(val.original);
    }

    /**
     * 个人中心下拉菜单更显示状态
     *
     * @param val
     */
    function personalUpdateStatus(val) {
        closeDropMenuStatus(1, true);

        personal.update(val);
    }

    /**
     * 菜单搜索更新显示状态
     *
     * val 为 true 时，则验证菜单搜索下拉框状态
     *
     * @param val
     */
    function searchUpdateStatus(val) {
        closeDropMenuStatus(!val ? 0 : 2, true);

        if (val) {
            search.update(val);

            // 搜索关键字存在，则展开搜索菜单列表
            if (search.keyword.length !== 0) search.updateDropStatus(true);
        }
    }

    /**
     * 菜单 缩放按钮切换点击
     */
    function menuSwitchBtnClick() {
        // 菜单缩放控制 0 展开 其他则为隐藏或收缩
        let status = menuZoomControl();

        // 控制 按钮 icon 展示
        config.menuBtnComponent.status = status === 0 ? 0 : 1;
    }

    /**
     * 面包屑导航点击
     *
     * @param val
     */
    function breadCrumbsNavClick(val) {
        // TODO 点击跳转未完成
    }

    return {
        search,
        personal,
        layTop,
        config,

        init,
        onInputSearchMenu,
        searchDropListClick,
        searchUpdateStatus,
        personalUpdateStatus,
        menuSwitchBtnClick,
        breadCrumbsNavClick
    }
}
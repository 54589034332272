// vue 相关
import {createApp} from 'vue'
import App from './App.vue'
import Router from './router'
import Store from './store'

// element-ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons'

// 多语言
import VueI18n from './language'

// 公共 css
import './assets/css/reset.css'
import './assets/css/init.css'
import './assets/css/animation.css'

const app = createApp(App);

// 挂载组件
for (let iconName in ElIconModules) {
    app.component(iconName, ElIconModules[iconName])
}

app.use(Store).use(ElementPlus, {
    i18n: VueI18n.global.t,
    size: 'small'
}).use(VueI18n).use(Router).mount('#app')

export default {
    'title': "Never abandon dreams",
    'search': "Search",
    'home': "Home",
    'personal-center': 'Personal center',
    'layout': 'Sign out',
    'account': 'Account',
    'password': 'Password',
    'login-title': 'Backstage system login',
    'login': 'Login',
}
<template>
	<div
		class="container"
		ref="labelLabelContainer"
	>
		<ul class="label__box flex-x-start">
			<li
				class="label__box__item  flex-x-AIC-cen user-unselect mouse-pointer"
				@click="select(item)"
				:class="{'label__box__item--select': item.id === selectMenu.id}"
				v-for="item in label"
			>
				<span class="label__box__item__mark" v-if="item.id === selectMenu.id"></span>
				<span class="label__box__item__text">{{ item.name }}</span>
				
				<el-icon
					class="label__box__item__icon--close flex-x-AIC-cen"
					v-if="item.close"
					@click.stop="close(item, selectMenu.id)"
				>
					<close/>
				</el-icon>
			</li>
		</ul>
	</div>
</template>

<script>
import layoutLabel from "@/use/layout_label";

export default {
	name: "LayoutLabel",
	
	props: {
		// 选中的菜单
		selectMenu: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	
	setup() {
		const {label, labelLabelContainer, select, close} = layoutLabel();
		
		return {
			label,
			labelLabelContainer,
			
			select,
			close
		}
	}
}
</script>

<style scoped>
.container {
	width: 100%;
	
	border-bottom: 1px solid #d8dce5;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%), 0 0 3px 0 rgb(0 0 0 / 4%);
	
	padding: 5px 0;
	
	overflow-x: auto;
}

.container::-webkit-scrollbar {
	height: 5px;
}

.container::-webkit-scrollbar-thumb {
	background: rgba(160, 149, 149, 0.4);
	
	border-radius: 10px;
}

.container::-webkit-scrollbar-track {
	background-color: transparent;
	
	border-radius: 10px;
}

.label__box {
	width: max-content;
	
	padding: 0 10px;
	
	position: relative;
}

.label__box__item {
	padding: 3px 10px;
	
	border: 1px solid #d8dce5;
	font-size: 0;
	
	margin-left: 5px;
	
	white-space: nowrap;
}

.label__box__item--select {
	background: #42b983;
	border-color: #42b983;
	
	color: #2c3e50;
}

.label__box__item--select .label__box__item__text {
	color: white;
	
}

.label__box__item__text {
	color: #495060;
	font-size: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small) - 1px));
	
	padding: 0 4px;
}

.label__box__item__mark {
	display: inline-block;
	
	width: 8px;
	height: 8px;
	background: white;
	border-radius: 100%;
}

.label__box__item__icon--close {
	width: 14px;
	height: 14px;
	
	background-color: transparent;
	border-radius: 100%;
	
	font-size: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small) - 2px));
	
	transition: background-color .3s;
}

.label__box__item--select .label__box__item__icon--close {
	color: white;
}

.label__box__item__icon--close:hover {
	background-color: #b4bccc;
}

</style>
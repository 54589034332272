<template>
	<transition name="animation--hover" mode="in-out">
		<div class="box" :style="{height: height + 'px', right: position.x + 'px', top: position.y + 'px'}" v-show="show">
			<div class="message-box">
				<span class="message-content">{{ content }}</span>
			</div>
		</div>
	</transition>

</template>

<script>

export default {
	name: "Message",
	
	props: {
		show: {
			type: Boolean,
			default: () => false
		},
		
		content: {
			type: String,
			default: () => ''
		},
		
		height: {
			type: Number,
			default: () => 0
		},
		
		position: {
			type: Object,
			default: () => {
				return {x: 0, y: 0}
			}
		}
	}
}
</script>

<style scoped>
.box {
	position: absolute;
	right: 0;
	top: 0;
	
	transform: translateX(100%);
}

.box--default {
	right: 0;
	top: 50%;
	
	transform: translate(100%, -50%);
}

.message-box {
	position: relative;
	top: 50%;
	
	background-color: #303133;
	color: #fff;
	border-radius: 5px;
	
	transform: translateY(-50%);
}

.message-box::before {
	content: '';
	
	display: block;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-right: 6px solid #303133;
	border-bottom: 5px solid transparent;
	
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-100%, -50%);
}

.message-content {
	display: inline-block;
	
	white-space: nowrap;
	padding: 5px 10px;
}
</style>
<template>
	<header class="flex-x-btw-AIC header">
		<div class="box flex-x-AIC">
			<div
				class="menu__btn flex-x-AIC hover--gray user-unselect mouse-pointer"
				@click="menuSwitchBtnClick"
			>
				<el-icon>
					<component :is="config.menuBtnComponent.list[config.menuBtnComponent.status]"></component>
				</el-icon>
			</div>
			
			<nav class="menu__nav">
				<span
					class="menu__nav__item"
					:class="[
						key === layTop.breadCrumbsNav.list.length ? ['menu__nav__item--select'] : [
							'user-unselect', 'mouse-pointer', 'hover--color-skyblue'
						]
					]"
					@click="key !== layTop.breadCrumbsNav.list.length ? breadCrumbsNavClick(item) : ''"
					v-for="(item, key) in [layTop.breadCrumbsNav.home, ...layTop.breadCrumbsNav.list]"
				>
					{{ item.i18n ? $t(item.name) : item.name }}
				</span>
			</nav>
		</div>
		
		<div class="box flex-x-AIC-FDR">
			
			<div class="menu__user user-unselect mouse-pointer">
				<div
					class="menu__user__box flex-x-AIC-cen hover--gray"
					@click.stop="personalUpdateStatus(!personal.show)"
				>
					<div class="menu__user__box__img img-box">
						<img src="@/assets/img/avatar.gif" alt="">
					</div>
				</div>
				
				<layout-top-drop-menu
					:list="layTop.personalDropList"
					:show="personal.show"
				></layout-top-drop-menu>
			</div>
			
			<div
				class="icon__full flex-x-AIC hover--gray user-unselect mouse-pointer"
				:class="{'icon__full--screen': fullStatus}"
				@click="switchFull"
			>
				<el-icon>
					<full-screen/>
				</el-icon>
			</div>
			
			<div class="menu__search flex-x-AIC" v-if="screenLevel !== 2">
				<div class="icon__search user-unselect mouse-pointer" @click.stop="searchUpdateStatus(!search.show)">
					<el-icon>
						<search/>
					</el-icon>
				</div>
				
				<transition name="animation--move-width">
					<div class="menu__search__input__box flex-x-AIC" v-show="search.show">
						<div class="menu__search__input">
							<input
								type="text"
								:placeholder="$t('message.search')"
								v-model="search.keyword"
								@input="onInputSearchMenu"
								@click.stop="searchUpdateStatus(true)"
							>
						</div>
						
						<layout-top-drop-menu
							:show="search.dropStatus"
							:list="search.data"
							@item-click="searchDropListClick"
						></layout-top-drop-menu>
					</div>
				</transition>
			</div>
		
		</div>
	</header>
</template>

<script>
import full from "@/use/full";
import {watch} from "vue"
import LayoutTopDropMenu from "@/components/LayoutTopDropMenu";
import layoutTop from "@/use/layout_top";

export default {
	name: "LayoutTop",
	
	components: {
		LayoutTopDropMenu
	},
	
	props: {
		menu: {
			type: Array,
			default: () => []
		},
		
		screenLevel: {
			type: Number,
			default: () => 0
		}
	},
	
	setup(prop) {
		let {
			init,
			search,
			personal,
			layTop,
			config,
			
			onInputSearchMenu,
			searchDropListClick,
			searchUpdateStatus,
			personalUpdateStatus,
			menuSwitchBtnClick,
			breadCrumbsNavClick
		} = layoutTop();
		
		// 执行默认操作
		init(prop.menu);
		
		// 全屏切换
		const {full: fullStatus, switchFull} = full();
		
		// 监听设备变化
		watch(() => prop.screenLevel, val => {
			if (val === 2) searchUpdateStatus(false);
		});
		
		return {
			layTop,
			fullStatus,
			personal,
			search,
			config,
			
			switchFull,
			onInputSearchMenu,
			searchDropListClick,
			searchUpdateStatus,
			personalUpdateStatus,
			menuSwitchBtnClick,
			breadCrumbsNavClick
		}
	}
}
</script>

<style scoped>
.header {
	width: 100%;
	height: 50px;
}

.box {
	height: 100%;
}

.menu__btn {
	height: 100%;
	
	background-color: transparent;
	
	transition: background-color .5s;
}

.menu__btn i {
	padding: 0 14px;
	
	font-size: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small) + 8px));
}

.menu__nav {
	margin-left: 10px;
	
	line-height: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small)) + 1px);
}

.menu__nav__item {
	color: black;
	
	font-size: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small)) + 1px);
}

.menu__nav__item--select {
	color: #97a8be;
}

.menu__nav__item::after {
	content: "/";
	margin: 0 9px;
	
	color: #97a8be;
	font-size: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small)) - 2px);
}

.menu__nav__item:last-child::after {
	content: "";
	padding: 0;
}

.menu__user {
	width: 60px;
	height: 100%;
	
	margin-right: 10px;
	
	position: relative;
}

.menu__user__box {
	width: 100%;
	height: 100%;
}

.menu__user__box__img {
	width: 40px;
	height: 40px;
	border-radius: 10px;
	overflow: hidden;
}

.menu__user__box img {
	height: 100%;
}

.icon__full {
	height: 100%;
}

.icon__full i {
	padding: 0 7px;
	
	font-size: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small) + 8px));
}

.icon__full--screen i {
	font-size: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small) + 15px));
}

.menu__search {
	height: 100%;
	
	margin-right: 5px;
}

.icon__search {
	font-size: 0;
}

.icon__search i {
	font-size: calc(var(--rain-font-size-zoom) * (var(--rain-font-size-small) + 6px));
}

.menu__search__input__box {
	width: 200px;
	height: 100%;
	
	margin-left: 5px;
	
	position: relative;
}

.menu__search__input {
	width: 100%;
}

.menu__search__input input {
	width: 100%;
	
	padding: 7px 0 7px 2px;
	font-size: 1rem;
	color: #747a80;
	
	border: none;
	border-bottom: 1px solid #bfcbd9;
	outline: none;
}

.menu__search__input__box >>> .drop__list {
	width: 100%;
}

.menu__search__input__box >>> .drop__list::before {
	left: 21px;
	right: unset;
}

.menu__search__input__box >>> .drop__list::after {
	left: 21px;
	right: unset;
}

.menu__search__input__box >>> .drop__list li {
	width: 100%;
	
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
}

/** 触碰背景灰白变化 **/
.hover--gray {
	transition: all .5s;
}

.hover--gray:hover {
	background: rgba(168, 158, 158, 0.1);
}

/** 触碰字体颜色变化 **/
.hover--color-skyblue {
	transition: color .5s;
}

.hover--color-skyblue:hover {
	color: #1890ff;
}
</style>
<template>
	<div class="menu" :class="{'menu--sub': level > 1}" v-show="show">
		<ul>
			<li
				class="menu__item"
				:class="{'menu__item--select': selectMenu.id === item.id}"
				v-for="item in menu"
				:key="item.id"
			>
				<div
					class="menu__item__box flex-x-AIC user-unselect mouse-pointer"
					:style="{'padding-left': level * config['level-padding'] + 'px'}"
					@click="click(item)"
					@mouseenter="screen === 1 ? hover($event, item, true): ''"
					@mouseleave="screen === 1 ? hover($event, item, false) : ''"
				>
					<el-icon
						class="menu__item__box__icon"
						:class="{'menu__item__box--show-color': selectMenu.pid.includes(item.id)}"
						v-if="item.icon.length !== 0"
					>
						<component :is="item.icon"></component>
					</el-icon>
					
					<template v-if="screen === 0">
						<span
							class="menu__item__box__text rain-grow"
							:class="{'menu__item__box--show-color': selectMenu.pid.includes(item.id)}"
							:style="{'padding-left': item.icon.length !== 0 ? '10px' : '0'}"
						>
							{{ item.name }}
						</span>
						
						<el-icon
							v-if="item.children.length !== 0"
							class="sub-menu__icon"
							:class="[item.show ? 'sub-menu__icon--up' : 'sub-menu__icon--down']"
						>
							<arrow-down/>
						</el-icon>
					</template>
				
				</div>
				
				<template v-if="item.children.length !== 0 && screen === 0">
					
					<transition
						name="stretch"
						@before-enter="beforeEnter"
						@enter="enter"
						@after-enter="afterEnter"
						@before-leave="beforeLeave"
						@leave="leave"
						@after-leave="afterLeave"
					>
						<menu-vertical
							:menu="item.children"
							:show="item.show"
							:config="config"
							:level="level + 1"
							:screen="screen"
							:select-menu="selectMenu"
							@menu-click="click"
							@menu-hover="hover"
						></menu-vertical>
					
					</transition>
				
				</template>
			
			
			</li>
		</ul>
	</div>
</template>

<script>
import heightShrink from "@/use/height_shrink";
import MenuVertical from "@/components/MenuVertical";

export default {
	name: "MenuVertical",
	
	components: {
		MenuVertical,
	},
	
	props: {
		// 菜单列表
		menu: {
			type: Array,
			
			default: () => []
		},
		
		// 菜单配置
		config: {
			type: Object,
			
			default: () => {
				return {
					// 不同层级的菜单间隔距离
					'level-padding': 20,
				};
			}
		},
		
		// 是否展示子菜单
		show: {
			type: Boolean,
			
			default: () => false,
		},
		
		// 当前级别
		level: {
			type: Number,
			
			default: () => 1
		},
		
		// 设备级别
		screen: {
			type: Number,
			default: () => 0
		},
		
		// 选中的菜单
		selectMenu: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	
	setup(prop, {emit}) {
		// 触碰、点击 菜单
		const [hover, click] = [
			(e, menu, status) => {
				emit('menu-hover', e, menu, status);
			},
			
			(menu) => {
				emit('menu-click', menu);
			}
		];
		
		return {
			// 菜单收缩动画
			...heightShrink('0'),
			
			hover,
			click
		};
	},
}
</script>

<style scoped>
.menu {
	width: 100%;
	
	overflow: hidden;
}

.menu__item {
	width: 100%;
	font-size: calc(var(--rain-font-size-zoom) * var(--rain-font-size-small));
	color: rgb(191, 203, 217);
	
	overflow: hidden;
}

.menu__item--select {
	color: skyblue;
}

.menu__item__box {
	width: 100%;
	height: 50px;
	
	padding: 0 20px;
	line-height: 50px;
	box-sizing: border-box;
}

.menu__item__box:hover {
	background-color: rgba(0, 0, 0, .2);
}

.menu__item__box--show-color {
	color: #f4f4f5;
}

.menu__item__box__text {
	width: 80%;
	text-align: left;
	
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.sub-menu__icon {
	transition: transform .5s ease;
}

.sub-menu__icon--down {
	transform: rotate(0deg);
}

.sub-menu__icon--up {
	transform: rotate(180deg);
}

/********* 高度伸缩动画 *********/
.stretch-enter-active, .stretch-leave-active {
	transition: height .5s ease;
}

/** 平板使用时 **/
@media screen and (max-width: 1000px) {
}
</style>
import {createStore} from "vuex";

export default createStore({
    state: {
        // 字体尺寸
        fontSize: 1,

        // 屏幕宽度级别，0 PC 1 平板 2 手机
        screenLevel: 0,

        // 当前选中的菜单
        selectMenu: {
            id: 0,

            // 所有父级ID
            pid: [],
        },
    },

    mutations: {
        /**
         * 更新字体尺寸
         *
         * @param state
         * @param payload
         */
        updateFontSize(state, payload) {
            state.fontSize = payload.fontSize;
        },

        /**
         * 更新屏幕宽度级别
         *
         * @param state
         * @param payload
         */
        updateScreenLevel(state, payload) {
            state.screenLevel = payload.level;
        },

        /**
         * 更新选中菜单
         *
         * @param state
         * @param payload
         */
        updateSelectMenu(state, payload) {
            state.selectMenu = payload;
        }
    },

    actions: {},
});

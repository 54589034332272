import layout from "@/use/layout";

// 标签列表
const {label, handlerMenuClickSelectLogic, labelLabelContainer} = layout();
export default function () {
    /**
     * 选中标签
     *
     * @param val
     */
    function select(val) {
        handlerMenuClickSelectLogic(val);
    }

    /**
     * 关闭标签
     *
     * @param val
     * @param selectId
     */
    function close(val, selectId) {
        let [i, len] = [0, label.length];
        for (i, len; i < len; i++) {
            if (val.id === label[i].id) {
                label.splice(i, 1);

                break;
            }
        }

        // 删除当前选中菜单，选中菜单更改为上一个
        if (selectId === val.id) handlerMenuClickSelectLogic(label[i - 1]);
    }

    return {
        label,
        labelLabelContainer,

        select,
        close
    }
}
/**
 * 防抖，一定时间内，多次执行，永远执行最后一个
 *
 * @param timer
 * @param fn
 * @param time
 * @returns {number}
 */
export function throttling(timer, fn, time = 500) {
    if (timer) clearTimeout(timer);

    return setTimeout(fn, time);
}

/**
 * 处理无限级列表，嵌套级别
 *
 * @param list  列表
 * @param pid   父级ID
 * @param level 子列表级别
 * @param attr  额外自定义属性
 * @returns {[]}
 */
export function handlerUnlimitedList(list, pid, level, attr) {
    let data = [];

    list.forEach(item => {
        if (item.pid === pid) data.push({
            ...item,
            ...attr,

            level,
            children: handlerUnlimitedList(list, item.id, level + 1, attr)
        });
    });

    return data;
}

/**
 * 通过 某个子列表父级ID 获取无限级列表顶部
 *
 * @param list  列表
 * @param pid   父级ID
 * @param format    是否需处理列表格式
 *
 * @returns {{data: [], list: *}}
 */
export function getUnlimitedListTop(list, pid, format = true) {
    // 是否需处理列表格式
    if (format) {
        list = list.reduce((result, item) => {
            result[item.id] = item;

            return result;
        }, {});
    }

    let data = [];
    while (pid !== 0) {
        data.unshift(list[pid]);

        pid = list[pid].pid;
    }

    return {data, list};
}

/**
 * 处理 多级对象，获取每级路径的值，并操作
 *
 * @param list      列表
 * @param data      路径列表
 * @param callback  回调操作
 */
export function handlerObjectPath(list, data, callback) {
    let info = data.shift();

    for (let item of list) {
        if (item.id === info.id) {
            callback(item);

            if (data.length !== 0) handlerObjectPath(item.children, data, callback);
            break;
        }
    }
}
<template>
	<transition name="drop-list">
		<ul
			class="drop__list"
			:class="[
					list.length !== 0 ? [
						'flex-y-wrap-ACB'
					] : [
						'flex-x-wrap-cen'
					]
				]"
			@click.stop
			v-if="show"
		>
			<template v-if="list.length !== 0">
				<li
					class="drop__list__item user-unselect mouse-pointer"
					:title="item.i18n ? $t(item.name) : item.name"
					:class="{'drop__list__item--separate': item.show === 1}"
					v-for="item in list"
					@click="click(item)"
				>
					<span class="overflow-line1">{{ item.i18n ? $t(item.name) : item.name }}</span>
				</li>
			</template>
			
			<template v-else>
				<span class="drop__list--space__span">无数据</span>
			</template>
		
		</ul>
	</transition>
</template>

<script>
import {useRoute} from "vue-router";

export default {
	name: "LayoutTopDropMenu",
	
	props: {
		list: {
			type: Array,
			default: () => []
		},
		
		show: {
			type: Boolean,
			default: () => false,
		}
	},
	
	setup(prop, {emit}) {
		const route = useRoute();
		
		// 方法集合
		const methods = {
			// 点击列表
			click: (val) => {
				emit('item-click', val);
				
				// 跳转页面
				if (val.url.length !== 0) {
					if (val.jump === 1) window.open(val.url);
					
					if (val.jump === 0) route.push(val.url);
				}
			}
		};
		
		return {
			...methods
		}
	}
}
</script>

<style scoped>

.drop__list {
	width: max-content;
	
	position: absolute;
	right: 0;
	top: 50px;
	z-index: 1;
	
	background: white;
	
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	
	padding: 8px 0 6px;
	margin-top: 10px;
	
	border: 1px solid #e6ebf5;
	border-radius: 3px;
	
	transform-origin: center top;
}

.drop__list::before {
	content: "";
	
	display: block;
	border-width: 6px;
	border-style: solid;
	border-color: transparent transparent #e6ebf5 transparent;
	
	position: absolute;
	top: -12px;
	right: 21px;
}

.drop__list::after {
	content: "";
	
	display: block;
	border-width: 6px;
	border-style: solid;
	border-color: transparent transparent white transparent;
	
	position: absolute;
	top: -10px;
	right: 21px;
}

.drop__list--space__span {
	color: #97a8be;
	
	line-height: calc(var(--rain-font-size-zoom) * var(--rain-font-size-small) * 2.5);
	font-size: calc(var(--rain-font-size-zoom) * var(--rain-font-size-small));
}

.drop__list__item {
	line-height: calc(var(--rain-font-size-zoom) * var(--rain-font-size-small) * 2.5);
}

.drop__list__item:hover {
	background-color: #e8f4ff;
	color: #46a6ff;
}

.drop__list__item--separate {
	border-top: 1px solid #e6ebf5;
	
	margin-top: 6px;
}

.drop__list__item--separate::before {
	content: '';
	
	display: block;
	height: 6px;
	background-color: white;
}

.drop__list__item span {
	padding: 0 15px;
	font-size: calc(var(--rain-font-size-zoom) * var(--rain-font-size-small));
}

</style>
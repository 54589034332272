<template>
	<router-view/>
</template>

<script>
import {throttling} from "@/assets/js/function";
import {watch} from 'vue'
import {useStore} from 'vuex'
import Layout from "@/views/Layout";

export default {
	name: 'App',
	
	components: {Layout},
	
	setup() {
		// 仓库管理，根元素
		const [store, room] = [
			useStore(),
			document.querySelector(':root')
		]
		
		// 设置字体大小缩放比例、计算屏幕宽度级别
		const [setFontSize, calculateScreenWidth] = [
			(fontSize) => {
				room.setAttribute('style', `--rain-font-size-zoom: ${fontSize}`);
			},
			
			() => {
				let [width, screenLevel] = [document.body.clientWidth, 0];
				
				if (750 >= width) screenLevel = 2;
				if (750 < width && 1000 >= width) screenLevel = 1;
				
				store.commit('updateScreenLevel', {level: screenLevel});
			}
		];
		
		// 设置默认字体大小缩放比例
		setFontSize(store.state.fontSize);
		
		// 监听字体大小变化
		watch(() => store.state.fontSize, val => {
			setFontSize(val);
		});
		
		// 设置默认屏幕宽度级别
		calculateScreenWidth();
		
		// 监听屏幕宽度（设备）
		let screenTimer;
		window.onresize = () => {
			screenTimer = throttling(screenTimer, calculateScreenWidth, 100);
		}
		
		return {}
	},
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nprogress .bar {
	background: #1890ff !important;
}
</style>

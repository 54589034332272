import {createRouter, createWebHashHistory} from "vue-router";
import NProgress from "nprogress";
import 'nprogress/nprogress.css'

const routes = [
    {
        path: "/",
        name: "Layout",
        component: () => import('../views/Layout'),
        children: [
            {
                path: "/",
                name: "Layout",
                component: () => import('../views/Home'),
                alias: "/home"
            }
        ]
    },

    {
        path: "/login",
        name: "Login",
        component: () => import('../views/Login'),
    }
];

const router = createRouter({
    history: createWebHashHistory(),

    routes,
})

// 路由跳转前
router.beforeEach((to, from, next) => {
    NProgress.start();

    next();
});

// 路由跳转后
router.afterEach(() => {
    NProgress.done();
});


export default router;
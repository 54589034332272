<template>
	<div class="layout flex-x">
		<aside
			class="aside"
			:class="{
			'aside--ipad': menu.screen === 1,
			'aside--phone': menu.screen === 2,
			'aside--phone--masking': menu.masking.switch
			}"
		>
			<div
				class="aside__menu"
				ref="asideMenuDom"
			>
				<menu-vertical
					:menu="menu.list"
					:show="true"
					:config="menu.config"
					:screen="menu.screen"
					:select-menu="selectMenu"
					@menu-hover="menuHover"
					@menu-click="menuClick"
				></menu-vertical>
			</div>
			
			<div class="aside__menu--bullet-frame">
				<message
					:show="menu.messageConfig.show"
					:content="menu.messageConfig.content"
					:height="menu.messageConfig.height"
					:position="menu.messageConfig.position"
				></message>
			</div>
		
		</aside>
		
		<masking
			:show="menu.masking.show"
			@masking-click="menuUpdateMaskingShow(false, true)"
		></masking>
		
		<section class="section grow flex-y-AIS">
			<layout-top :menu="menu.list" :screen-level="screenLevel"></layout-top>
			
			<layout-label
				:select-menu="selectMenu"
			></layout-label>
			
			<main class="main grow">
				<router-view/>
				
				<footer style="background-color: #f0f2f5; margin-bottom: 10px;">
					<a href="https://beian.miit.gov.cn/" style="color: #2c3e50;" target="_blank">赣ICP备17013089号-1</a>
				</footer>
			</main>
		</section>
	</div>
</template>

<script>
import {watch, computed} from "vue";
import {useStore} from "vuex";
import layout from "@/use/layout";
import MenuVertical from "@/components/MenuVertical";
import Message from "@/components/Message"
import LayoutTop from "@/components/LayoutTop"
import LayoutLabel from "@/components/LayoutLabel";
import Masking from "@/components/Masking";

export default {
	name: "Layout",
	
	components: {MenuVertical, Message, LayoutTop, LayoutLabel, Masking},
	
	setup() {
		// 菜单相关配置，菜单切换函数，更新菜单蒙层，更新菜单蒙层开关
		const {menu, asideMenuDom, init, menuClick, menuHover, menuSetScreen, menuUpdateMaskingShow, menuUpdateMaskingSwitch} = layout();
		init();
		
		const [store] = [useStore()];
		
		// 菜单选中状态
		let selectMenu = computed(() => store.state.selectMenu);
		
		// 当前设备级别
		let screenLevel = computed(() => store.state.screenLevel);
		
		// 设置默认设备级别
		menuSetScreen(store.state.screenLevel);
		
		// 设置默认菜单蒙层开关状态
		menuUpdateMaskingSwitch(store.state.screenLevel === 2);
		
		// 监听屏幕级别切换
		watch(() => store.state.screenLevel, val => {
			menuSetScreen(val);
			
			// 更新菜单蒙层
			if (menu.masking.show) menuUpdateMaskingShow(false, false);
			
			// 更新菜单蒙层状态
			menuUpdateMaskingSwitch(val === 2, val === 2 ? 500 : 2);
		});
		
		return {
			menu,
			asideMenuDom,
			selectMenu,
			screenLevel,
			
			menuClick,
			menuHover,
			menuUpdateMaskingShow
		}
	},
}
</script>

<style scoped>
.layout {
	height: 100vh;
	
	overflow: hidden;
	
	position: relative;
}

.aside {
	width: 210px;
	height: 100%;
	background-color: #304156;
	
	position: relative;
	
	transition: width .5s ease;
}

.aside--ipad {
	width: calc(40px + var(--rain-font-size-zoom) * var(--rain-font-size-small));
}

.aside--phone {
	width: 0;
}

.aside--phone--masking {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 101;
}

.aside__menu {
	width: 100%;
	height: 100%;
	
	box-sizing: border-box;
	overflow: visible auto;
}

.aside__menu::-webkit-scrollbar {
	width: 5px;
}

.aside__menu::-webkit-scrollbar-thumb {
	background: rgba(160, 149, 149, 0.4);
	
	border-radius: 10px;
}

.aside__menu::-webkit-scrollbar-track {
	background-color: transparent;
	
	border-radius: 10px;
}

.aside__menu--bullet-frame {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 999;
	
	transform: translateX(calc(100% + 3px));
}

/** 竖排菜单子菜单背景颜色 **/
.menu >>> .menu--sub {
	background-color: #1f2d3d;
}

.section {
	overflow: hidden;
}

.main {
	width: 100%;
	
	overflow-y: auto;
	overflow-x: hidden;
}

.main::-webkit-scrollbar {
	width: 5px;
}

.main::-webkit-scrollbar-thumb {
	
	background: rgba(160, 149, 149, 0.4);
	border-radius: 10px;
}

.main::-webkit-scrollbar-track {
	background-color: rgba(160, 149, 149, 0.1);
	
	border-radius: 10px;
}
</style>
<template>
	<div
		class="container"
		@click="click"
		v-show="show"
	></div>
</template>

<script>
export default {
	name: "Masking",
	
	props: {
		show: {
			type: Boolean,
			default: () => false
		}
	},
	
	setup(prop, {emit}) {
		const methods = {
			click: () => {
				emit('masking-click')
			}
		};
		
		return {
			...methods
		}
	}
}
</script>

<style scoped>
.container {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .4);
	
	position: absolute;
	left: 0;
	top: 0;
	z-index: 100;
}
</style>
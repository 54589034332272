export default {
    'title': "不弃梦想",
    'search': "搜索",
    'home': "首页",
    'personal-center': '个人中心',
    'layout': '退出登录',
    'account': '账号',
    'password': '密码',
    'login-title': '后台系统登录',
    'login': '登录'
}